import { Slot } from '@radix-ui/react-slot'
import { cva, VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from 'src/utils'

const listItemVariants = cva(
  'relative w-full text-left border-b transition-colors duration-200 ease-in-out disabled:pointer-events-none disabled:bg-disabled-bg disabled:border-disabled-bg disabled:text-disabled-text disabled:after:bg-disabled-bg focus:bg-secondary-focused active:bg-secondary-pressed',
  {
    variants: {
      variant: {
        default: 'bg-white border-blue-gray-2',
        static: 'bg-white border-blue-gray-2',
        profile: 'flex items-center justify-between bg-gray-50',
        order: 'bg-white border-blue-gray-3',
      },
      size: {
        default: 'px-2 py-4',
        lg: 'px-4 py-5',
        md: 'px-2 py-3',
        sm: 'px-2 py-1.5',
        panel: 'px-4 py-3',
        history: 'px-4 py-3',
        claim: 'py-1.5 px-0',
      },
    },
    compoundVariants: [
      {
        variant: 'static',
        class: 'focus:bg-white active:bg-white',
      },
      {
        size: 'panel',
        class:
          'border-none after:w-[calc(100%-32px)] after:absolute after:h-[1px] after:bg-blue-gray-2 after:top-0 after:left-0 after:translate-x-4 mb-[-1px]',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

type ListItemProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof listItemVariants> & {
    asChild?: boolean
  }

const ListItem = React.forwardRef<HTMLButtonElement, ListItemProps>(
  ({ className = '', variant, size, asChild, children, ...rest }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp className={cn(listItemVariants({ variant, size, className }))} ref={ref} {...rest}>
        {children}
      </Comp>
    )
  },
)

ListItem.displayName = 'ListItem'

export { ListItem, listItemVariants }
