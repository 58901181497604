import { useRouter } from 'next/router'
import { ReactElement, ReactNode } from 'react'

import { Button, Icon, Skeleton, Typography } from 'src/components/ui'

//Utils
import { cn, orNull, ternary } from 'src/utils'

type HeaderProps = {
  children?: ReactNode
  title?: ReactElement | string | null
  subTitle?: ReactElement | string | null
  onHeaderBack?: () => void
  backArrow?: ReactElement | null
  actionsRight?: ReactElement | null
  withShadow?: boolean
  loadingFetch?: boolean
  className?: string
}

export const Header = ({
  title,
  subTitle,
  backArrow,
  actionsRight,
  onHeaderBack,
  withShadow = false,
  loadingFetch = false,
  children,
  className = '',
}: HeaderProps) => {
  const router = useRouter()

  return (
    <header className={cn('relative z-20 w-full', withShadow && 'shadow-level-2', className)}>
      <div className={cn('flex justify-between px-2 py-1', !subTitle && 'items-center')}>
        <div className='flex h-full min-w-[40px] flex-none items-center gap-2'>
          {backArrow || (
            <Button variant='ghost' size='icon-sm' onClick={onHeaderBack || router.back}>
              <Icon name='ArrowLeft' color='blue-gray-85' />
            </Button>
          )}
        </div>
        <div className='flex h-full w-full items-center justify-center overflow-hidden px-2'>
          {ternary(
            loadingFetch,
            <Skeleton className='h-6 w-full' />,
            <div className='flex flex-col overflow-hidden text-center'>
              <Typography truncate='line' className='font-semibold'>
                {title}
              </Typography>
              <>
                {orNull(
                  subTitle,
                  <Typography variant='caption' color='gray-7' truncate='line'>
                    {subTitle}
                  </Typography>,
                )}
              </>
            </div>,
          )}
        </div>
        <div className='flex h-full min-w-[40px] flex-none items-center justify-end gap-2'>{actionsRight}</div>
      </div>
      {children}
    </header>
  )
}
