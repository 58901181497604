import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { AlertDialog, AlertDialogViewsSwitcher, ClaimItem, SkeletonList } from 'src/components/common'
import { Empty, InfiniteScroll } from 'src/components/ui'

import { useClaimsDelete, useClaimsListFetch } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const Claims = () => {
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { claimsList, loadingClaimsListFetch } = useClaimsListFetch()
  const { onDeleteClaim, loadingClaimsDelete } = useClaimsDelete()
  const { customersDetails } = useCustomersDetailsFetch()

  const [deleteId, setDeleteId] = useState<number>()

  const renderItems = () => {
    return claimsList.items.map((item) => (
      <ClaimItem key={item.id} item={item} onDelete={setDeleteId} customerId={customersDetails?.id} />
    ))
  }

  return (
    <>
      <AlertDialog
        open={onPageDialogs[GET_ENUMS.alertDialog.confirm]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.alertDialog.confirm, open)}
      >
        <AlertDialogViewsSwitcher
          name={GET_ENUMS.alertDialog.confirm}
          params={{
            title: t('are_you_sure'),
            buttonVariant: 'cta',
            loadingFetch: loadingClaimsDelete,
            onConfirm: async () => deleteId && (await onDeleteClaim(deleteId)),
          }}
        />
      </AlertDialog>
      <InfiniteScroll
        hasMore={false}
        loadingFetch={loadingClaimsListFetch}
        onFetchMore={() => undefined}
        dataLength={claimsList.items.length}
        loader={<SkeletonList type='claims' />}
        scrollableTarget='claims'
        renderItems={renderItems}
        empty={<Empty title='common:empty.no_activity' description='common:empty.claims_list' />}
      />
    </>
  )
}
