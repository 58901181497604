import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { cond, equals } from 'ramda'
import React, { useState } from 'react'
import { getTranslate } from 'utils/helpers'
import { GET_ENUMS, getClaimItemActions, orNull, ternary } from 'utils/index'

import { book } from 'src/init/book'

import { WrappedNodeDefault } from 'src/components/common/index'
import { Icon, ListItem, Separator, SwipeAction, Typography } from 'src/components/ui'

import { ClaimType } from 'src/models'

import { useCurrencyFormat } from 'src/hooks'

import { useDialog } from 'src/contexts'

type ClaimItemProps = {
  item: ClaimType
  customerId?: number
  onDelete: (id: number) => void
}

export const ClaimItem = ({ item, onDelete, customerId }: ClaimItemProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()
  const { currencyFormat } = useCurrencyFormat()

  const [expanded, setExpanded] = useState(false)

  const getReturnsT = getTranslate('returns:returns_details', t)

  const pathToDetails = {
    pathname: book.claims,
    query: {
      id: item.id,
      returnId: router.query.id,
      customerId: router.query.customerId || customerId,
    },
  }

  const onSelectCell = (type: string) => () =>
    cond([
      [
        equals('delete'),
        () => {
          if (item.isDeletable) {
            onDelete(item.id)
            setOnPageDialog(GET_ENUMS.alertDialog.confirm, true)
          }
        },
      ],
    ])(type)

  const claimActions = getClaimItemActions({ onSelectCell, item, t })

  const toggleExpand = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setExpanded((prev) => !prev)
  }

  const status = item.status

  const getIconColor = () => (status === 'confirmed' ? 'done' : status === 'refused' ? 'cancel' : 'blue-gray-4')

  const content = (
    <ListItem size='claim'>
      <Link href={pathToDetails}>
        <div className='grid grid-cols-[40px_1fr_1fr_40px] gap-2'>
          <div className='grid items-center justify-center'>
            <Icon name='CompletedFill' width={20} height={20} color={getIconColor()} />
          </div>
          <div className='grid items-center'>
            <Typography variant='secondary'>{WrappedNodeDefault(item.article)}</Typography>
          </div>
          <div className='grid items-center'>
            <Typography variant='secondary'>{WrappedNodeDefault(item?.cause?.label)}</Typography>
            <Typography variant='secondary' color='gray-7'>
              {getReturnsT('qty')} - {WrappedNodeDefault(item?.quantity?.label)}
            </Typography>
          </div>
          <div className='grid items-center justify-center' onClick={toggleExpand}>
            <Icon name={expanded ? 'ChevronUp' : 'ChevronDown'} color='blue-gray-7' />
          </div>
        </div>
      </Link>
      {orNull(
        expanded,
        <>
          <Separator className='mt-2' />
          <div className='grid grid-cols-2 gap-2 px-2 py-2'>
            <Typography variant='secondary' color='gray-7'>
              {getReturnsT('order_number')}
            </Typography>
            <Typography variant='secondary'>{WrappedNodeDefault(item.orderId)}</Typography>
          </div>
          <Separator />
          <div className='grid grid-cols-2 gap-2 px-2 py-2'>
            <Typography variant='secondary' color='gray-7'>
              {getReturnsT('amount')}
            </Typography>
            <Typography variant='secondary'>{currencyFormat(item.amount)}</Typography>
          </div>
          <Separator />
          <div className='grid grid-cols-2 gap-2 px-2 pt-2'>
            <Typography variant='secondary' color='gray-7'>
              {getReturnsT('status')}
            </Typography>
            <Typography variant='secondary'>{WrappedNodeDefault(item.status)}</Typography>
          </div>
        </>,
      )}
    </ListItem>
  )

  return (
    <>
      {ternary(item.isDeletable && !expanded, <SwipeAction leftActions={claimActions}>{content}</SwipeAction>, content)}
    </>
  )
}
