import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { ClaimsAutocompleteItem } from 'src/components/common'
import { Loader, Typography } from 'src/components/ui'

import { ClaimType } from 'src/models'

import { getHasData, getParamsFromQueryStr, ternary } from 'src/utils'

type ClaimsAutocompleteProps = {
  claims?: ClaimType[]
  loadingFetch?: boolean
}

export const ClaimsAutocomplete = ({ claims = [], loadingFetch = false }: ClaimsAutocompleteProps) => {
  const { t } = useTranslation()
  const router = useRouter()

  const initialQuery = getParamsFromQueryStr(router.query)
  const search = initialQuery.filter?.search || ''

  const filteredClaims = useMemo(() => {
    return search ? claims.filter((item) => item.article.toLowerCase().includes(search.toLowerCase())) : []
  }, [claims, search])

  if (loadingFetch) {
    return (
      <div className='flex items-center justify-center py-2'>
        <Loader type='relative' />
      </div>
    )
  }

  return (
    <>
      {ternary(
        getHasData(filteredClaims),
        <>
          {filteredClaims.map((item) => (
            <ClaimsAutocompleteItem key={item.id} item={item} />
          ))}
        </>,
        <div className='mb-2 mt-5 flex items-center justify-center'>
          <Typography variant='secondary' color='gray-7'>
            {t('empty.current_returns')}
          </Typography>
        </div>,
      )}
    </>
  )
}
