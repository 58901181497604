import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormFields,
  FormItem,
  FormMessage,
  Separator,
  Skeleton,
  Typography,
} from 'src/components/ui'

import {
  FormSubmitFn,
  ClaimStatusEnum,
  StaticDataItemType,
  FormFillDataClaimsEnum,
  FormUpdateClaimType,
} from 'src/models'

import { formShapes } from 'src/bus/forms'

import { CAUSE_COMMERCIAL, messages, orNull, ternary } from 'src/utils'

type EditClaimFormProps = {
  hasEdit?: boolean
  initialValues?: FormUpdateClaimType | null
  causes: StaticDataItemType[]
  quantity: StaticDataItemType[]
  additionalCauses: StaticDataItemType[]
  price: number
  loadingFetch?: boolean
  loadingUpdate?: boolean
  loadingCausesFetch?: boolean
  loadingQuantityFetch?: boolean
  loadingPriceFetch?: boolean
  loadingAdditionalCausesFetch?: boolean
  createdClaim?: boolean
  isNotEditable?: boolean
  onSelectCauseId: (id: number) => void
  onSelectQuantityId: (id: number) => void
  onSubmit: FormSubmitFn<FormUpdateClaimType>
}

export const FillDataClaimsForm = ({
  isNotEditable = false,
  initialValues = null,
  loadingFetch = false,
  loadingUpdate = false,
  loadingCausesFetch = false,
  loadingQuantityFetch = false,
  loadingPriceFetch = false,
  loadingAdditionalCausesFetch = false,
  createdClaim = false,
  additionalCauses,
  causes,
  quantity,
  price = 0,
  onSubmit,
  onSelectCauseId,
  onSelectQuantityId,
}: EditClaimFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormUpdateClaimType>({
    defaultValues: {
      ...formShapes.updateClaim.shape,
      ...initialValues,
    },
    resolver: zodResolver(formShapes.updateClaim.schema(t)),
  })

  const { control, handleSubmit, setError, reset, watch, setValue } = formProps

  const watchedFields = watch()

  const loadingOptionsFetch = loadingCausesFetch || loadingQuantityFetch || loadingPriceFetch
  const loadingRefuse = watchedFields.status === ClaimStatusEnum.refused && loadingUpdate
  const loadingConfirm = watchedFields.status === ClaimStatusEnum.confirmed && loadingUpdate

  const selectedCommercialCause = watchedFields.cause?.value === CAUSE_COMMERCIAL || false

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  useEffect(() => {
    if (isNil(watchedFields.cause) || isNotEditable) {
      return
    }

    onSelectCauseId(watchedFields.cause.id)
  }, [onSelectCauseId, watchedFields.cause, isNotEditable])

  useEffect(() => {
    if (isNil(watchedFields.quantity) || isNotEditable) {
      return
    }

    onSelectQuantityId(watchedFields.quantity.id)
  }, [onSelectQuantityId, watchedFields.quantity, isNotEditable])

  useEffect(() => {
    if (!price) {
      return
    }

    setValue('amount', price)
  }, [price, setValue])

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  const handleOk = () => {
    setValue('status', ClaimStatusEnum.confirmed)
  }

  const handleCancel = () => {
    setValue('status', ClaimStatusEnum.refused)
  }

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        scroll
        className='pt-4'
        footer={
          <div className='flex flex-col gap-2 px-2'>
            <Button
              type='submit'
              block
              disabled={loadingOptionsFetch || loadingFetch || loadingRefuse}
              loading={loadingConfirm}
              onClick={handleOk}
            >
              {t('buttons:next_step')}
            </Button>
            <Button
              type='submit'
              variant='ghost'
              className='text-cta-hover'
              block
              disabled={loadingOptionsFetch || isNotEditable || loadingFetch || loadingConfirm || !createdClaim}
              loading={loadingRefuse}
              onClick={handleCancel}
            >
              {t('buttons:reject')}
            </Button>
          </div>
        }
      >
        <div className='flex items-baseline justify-between'>
          <div className='basis-5/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('claims:type')}
            </Typography>
          </div>
          <div className='basis-8/12'>
            <FormField
              control={control}
              name={FormFillDataClaimsEnum.cause}
              render={({ field }) => (
                <FormItem gutter='none'>
                  <FormControl>
                    <FormFields.InputSelect
                      {...field}
                      hasDisabled={isNotEditable || loadingUpdate}
                      loading={loadingCausesFetch}
                      options={causes}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        {orNull(
          selectedCommercialCause,
          <>
            <Separator className='my-2' />
            <div className='flex items-baseline justify-between'>
              <div className='basis-5/12'>
                <Typography variant='secondary' color='gray-7'>
                  {t('claims:sub_type')}
                </Typography>
              </div>
              <div className='basis-8/12'>
                <FormField
                  control={control}
                  name={FormFillDataClaimsEnum.additionalCause}
                  render={({ field }) => (
                    <FormItem gutter='none'>
                      <FormControl>
                        <FormFields.InputSelect
                          {...field}
                          hasDisabled={isNotEditable || loadingUpdate}
                          loading={loadingAdditionalCausesFetch}
                          maxMenuHeight={260}
                          options={additionalCauses}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </>,
        )}
        <Separator className='my-2' />
        <div className='flex items-center justify-between'>
          <div className='basis-5/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('claims:order_number')}
            </Typography>
          </div>
          <div className='basis-8/12'>
            {ternary(
              loadingFetch,
              <Skeleton className='h-[22px]' />,
              <Typography variant='secondary'>{initialValues?.orderId}</Typography>,
            )}
          </div>
        </div>
        <Separator className='my-2' />
        <div className='flex items-baseline justify-between'>
          <div className='basis-5/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('claims:quantity')}
            </Typography>
          </div>
          <div className='basis-8/12'>
            <FormField
              control={control}
              name={FormFillDataClaimsEnum.quantity}
              render={({ field }) => (
                <FormItem gutter='none'>
                  <FormControl>
                    <FormFields.InputSelect
                      {...field}
                      hasDisabled={isNotEditable || isNil(watchedFields.cause) || loadingUpdate}
                      loading={loadingQuantityFetch}
                      options={quantity}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Separator className='my-2' />
        <div className='flex items-center justify-between'>
          <div className='basis-5/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('claims:total')}
            </Typography>
          </div>
          <div className='basis-8/12'>
            {ternary(
              loadingPriceFetch,
              <Skeleton className='h-[22px]' />,
              <Typography variant='secondary'>{watchedFields.amount}</Typography>,
            )}
          </div>
        </div>
        <Separator className='my-2' />
        <FormField
          control={control}
          name={FormFillDataClaimsEnum.comment}
          render={({ field }) => (
            <FormItem gutter='none'>
              <FormControl>
                <FormFields.InputTextArea
                  {...field}
                  placeholder={t(messages.returnComment.placeholder)}
                  disabled={isNotEditable || loadingUpdate}
                  rows={6}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
