import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { book } from 'src/init/book'

import { Link, WrappedNodeDefault, CornerEuro } from 'src/components/common'
import { Icon, ListItem, Separator, Typography } from 'src/components/ui'

import { ClaimType } from 'src/models'

import { useCurrencyFormat } from 'src/hooks'

import { getTranslate, orNull } from 'src/utils'

type ClaimsItemProps = {
  item: ClaimType
}

export const ClaimsAutocompleteItem = ({ item }: ClaimsItemProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { currencyFormat } = useCurrencyFormat()

  const [expanded, setExpanded] = useState(false)

  const getReturnsT = getTranslate('returns:returns_details', t)

  const returnId = router.query.id

  const pathToDetails = {
    pathname: book.claims,
    query: { returnId, id: item.id, customerId: router.query.customerId },
  }

  const toggleExpand = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setExpanded((prev) => !prev)
  }

  return (
    <ListItem size='claim'>
      {orNull(!item.isOrderPaid, <CornerEuro />)}
      <Link href={pathToDetails}>
        <div className='grid grid-cols-[1fr_1fr_40px] gap-2 pl-2'>
          <div className='grid items-center'>
            <Typography variant='secondary'>{WrappedNodeDefault(item.article)}</Typography>
          </div>
          <div className='grid items-center'>
            <Typography variant='secondary'>{WrappedNodeDefault(item?.cause?.label)}</Typography>
            <Typography variant='secondary' color='gray-7'>
              {getReturnsT('qty')} - {WrappedNodeDefault(item?.quantity?.label)}
            </Typography>
          </div>
          <div className='grid items-center justify-center' onClick={toggleExpand}>
            <Icon name={expanded ? 'ChevronUp' : 'ChevronDown'} color='blue-gray-7' />
          </div>
        </div>
      </Link>
      {orNull(
        expanded,
        <>
          <Separator className='mt-2' />
          <div className='grid grid-cols-2 gap-2 px-2 py-2'>
            <Typography variant='secondary' color='gray-7'>
              {getReturnsT('order_number')}
            </Typography>
            <Typography variant='secondary'>{WrappedNodeDefault(item.orderId)}</Typography>
          </div>
          <Separator />
          <div className='grid grid-cols-2 gap-2 px-2 py-2'>
            <Typography variant='secondary' color='gray-7'>
              {getReturnsT('amount')}
            </Typography>
            <Typography variant='secondary'>{currencyFormat(item.amount)}</Typography>
          </div>
          <Separator />
          <div className='grid grid-cols-2 gap-2 px-2 pt-2'>
            <Typography variant='secondary' color='gray-7'>
              {getReturnsT('status')}
            </Typography>
            <Typography variant='secondary'>{WrappedNodeDefault(item.status)}</Typography>
          </div>
        </>,
      )}
    </ListItem>
  )
}
