import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage, Typography } from 'src/components/ui'

import { FormSubmitFn, FormEditClaimEnum, FormUploadImagesType } from 'src/models'

import { formShapes } from 'src/bus/forms'

type EditClaimFormProps = {
  initialValues?: FormUploadImagesType | null
  loadingFetch?: boolean
  loadingUpdate?: boolean
  isNotEditable?: boolean
  onSubmit: FormSubmitFn<FormUploadImagesType>
  onPrevStep: () => void
}

export const UploadPhotoClaimsForm = ({
  isNotEditable = false,
  initialValues = null,
  loadingFetch = false,
  loadingUpdate = false,
  onSubmit,
  onPrevStep,
}: EditClaimFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormUploadImagesType>({
    defaultValues: { ...formShapes.uploadImagesClaims.shape, ...initialValues },
    resolver: zodResolver(formShapes.uploadImagesClaims.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        scroll
        className='pt-4'
        footer={
          <div className='flex flex-col gap-2 px-2'>
            <Button block disabled={loadingFetch} onClick={onPrevStep} type='button' variant='outline'>
              {t('buttons:prev_step')}
            </Button>
            <Button
              type='button'
              block
              disabled={loadingFetch || isNotEditable}
              loading={loadingUpdate}
              onClick={onSubmitForm}
            >
              {t('buttons:approve_return')}
            </Button>
          </div>
        }
      >
        <div className='mb-2'>
          <Typography variant='secondary'>{`${t('claims:add_necessary_photos', { qty: 2 })}:`}</Typography>
        </div>
        <div className='mb-2'>
          <Typography variant='secondary' color='gray-6'>{`${t('claims:max_file_size')}`}</Typography>
        </div>
        <FormField
          control={control}
          name={FormEditClaimEnum.images}
          render={({ field }) => (
            <FormItem gutter='none'>
              <FormControl>
                <FormFields.InputImageUploader
                  {...field}
                  hasDisabled={isNotEditable}
                  placeholder={t('claims:upload')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
